import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import VideoComponent from "./ui/video.component";

// import services
import televisionService from "../services/television.service";
import { toast } from "react-toastify";
import Spinner from "./ui/spinner.component";

export default function WaitingComponent(props) {
    const { t } = useTranslation();
    const [videos, setVideos] = useState([]);
    const [timeUntilMeeting, setTimeUntilMeeting] = useState(getTimeUntilMeeting(props.date));
    const [videoView, setVideoView] = useState();
    const [video, setVideo] = useState(false);

    useEffect(() => {
        televisionService.getAll()
            .then((response) => {
                const newVideos = response.data.map((element) => {
                    let title;
                    if (props.lang.toLowerCase() === "nl") {
                        title = element.title_nl;
                    } else if (props.lang.toLowerCase() === "fr") {
                        title = element.title_fr;
                    } else {
                        title = element.title_en;
                    }

                    return {
                        name: title,
                        uri: element.uri,
                    };
                });

                setVideos(newVideos);
            })
            .catch((error) => {
                toast.error("Er is iets fout gegaan bij het ophalen van de wachttelevisie.");
            });
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeUntilMeeting(getTimeUntilMeeting(props.date));
        }, 1000);

        return () => clearInterval(intervalId);
    }, [props.date]);

    return (
        <div class="dark" style={{ paddingBottom: "0" }}>
            <h2 className="text-4xl font-semibold mt-12">{t("meeting.start.patience")}</h2>
            <p className="mt-8 mb-8">
                {t("meeting.start.advice")}
            </p>
            <br />
            <br />
            {videos ?
                <>
                    <div style={{ maxWidth: "320px", width: "50%", margin: "0 auto" }}>
                        <div className="embed-container" style={{ margin: "25% auto 20%" }}>
                            <div className="embed options">
                                {videos.map((video) => (
                                    <button
                                        className="btn option"
                                        onClick={() => setVideoView(video.uri) & setVideo(true)}
                                    >
                                        {video.name}
                                    </button>
                                ))}
                            </div>
                            <div className="tv" />
                        </div>
                    </div>
                    <div style={{ maxWidth: "750px", margin: "-160px auto -60px", display: video ? "none" : "block" }}>
                        <div className="clock">
                            <div className="time">
                                <div className="days">{t("meeting.start.remaining")}{' '}{timeUntilMeeting.days} {timeUntilMeeting.days > 1 || timeUntilMeeting.days == 0 ? t("meeting.start.days") : t("meeting.start.day")}{' '}{t("meeting.start.and")}</div>

                                <div className="cou">{timeUntilMeeting.hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:</div>
                                <div className="cou">{timeUntilMeeting.minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:</div>
                                <div className="cou">{timeUntilMeeting.seconds.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}</div>

                            </div>
                        </div>
                    </div>
                    <VideoComponent uri={videoView} open={video} setOpen={setVideo} />
                </>
                : <>
                    <Spinner text={"TV laden..."} />
                </>}
        </div>
    );
}

function getTimeUntilMeeting(meetingDatetime) {
    const currentTime = new Date();
    const meetingTime = new Date(meetingDatetime);
    const timeDifference = meetingTime - currentTime;

    const secondsUntilMeeting = Math.floor(timeDifference / 1000);
    const minutesUntilMeeting = Math.floor(secondsUntilMeeting / 60);
    const hoursUntilMeeting = Math.floor(minutesUntilMeeting / 60);
    const daysUntilMeeting = Math.floor(hoursUntilMeeting / 24);

    return {
        days: daysUntilMeeting > 0 ? daysUntilMeeting : 0,
        hours: hoursUntilMeeting % 24 > 0 ? hoursUntilMeeting % 24 : 0,
        minutes: minutesUntilMeeting % 60 > 0 ? minutesUntilMeeting % 60 : 0,
        seconds: secondsUntilMeeting % 60 > 0 ? secondsUntilMeeting % 60 : 0,
    };
}